import React, { useState, useEffect, useRef } from 'react';
import './App.css';

function App() {
  const [hoverSection, setHoverSection] = useState<string | null>(null);
  const [overlayContent, setOverlayContent] = useState<string | null>(null);
  const [showRSVPForm, setShowRSVPForm] = useState(false);
  const cursorRef = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState(false);
  const touchPosRef = useRef({ x: 0, y: 0 });
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef<HTMLAudioElement>(null);
  const [showWelcome, setShowWelcome] = useState(true);

  const isTouchDevice = () => {
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
  };

  const handleMouseEnter = (sectionName: string) => {
    if (!isTouchDevice()) {
      setHoverSection(sectionName);
      if (cursorRef.current) {
        cursorRef.current.classList.add('section-hover');
      }
    }
  };

  const handleMouseLeave = () => {
    if (!isTouchDevice()) {
      setHoverSection(null);
      if (cursorRef.current) {
        cursorRef.current.classList.remove('section-hover');
      }
    }
  };

  const handleSectionClick = (sectionName: string) => {
    if (sectionName === 'RSVP') {
      setShowRSVPForm(true);
    } else {
      const content = `
        <div class="dialog-content">
          <h2>${sectionName}</h2>
          <p>${getAdventureText(sectionName)}</p>
        </div>
      `;
      setOverlayContent(content);
      if (sectionName === 'Registry') {
        setTimeout(initializeCamera, 100);
      }
    }
  };

  const handleRSVPSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    // Get form data
    const formData = new FormData(e.target as HTMLFormElement);
    const name = formData.get('name') as string;
    const email = formData.get('email') as string;
    const attending = formData.get('attending') as string;
    const plusOne = formData.get('plusOne') as string;
    const dietary = formData.get('dietary') as string;
    const message = formData.get('message') as string;

    // Only submit if attending
    if (attending === 'yes') {
      try {
        const response = await fetch('https://api.shehata.app/wedding/guests', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            name: name,
            email: email,
            dietary_restrictions: dietary || "",
            plus_one: plusOne, // You might want to collect plus one's name later
            rsvp_status: attending,
            message: message || ""
          })
        });

        if (!response.ok) {
          throw new Error('Failed to submit RSVP');
        }

        // Close the form after successful submission
        setShowRSVPForm(false);
        
        // Show a success message
        alert('Thank you for your RSVP!');
        
      } catch (error) {
        console.error('Error submitting RSVP:', error);
        alert('Sorry, there was an error submitting your RSVP. Please try again.');
      }
    } else {
      // Handle decline case
      setShowRSVPForm(false);
      alert('We\'ll miss you! Thanks for letting us know.');
    }
  };

  const getAdventureText = (sectionName: string) => {
    switch (sectionName) {
      case 'Venue':
        return `
          <div class="venue-info">
            <div class="venue-section">
              <h3>Ceremony Information</h3>
              <p>Date: July 20th, 2025</p>
              <p>Start time: TBD</p>
              <p>Location: Quinta Nova do Hespanhol</p>
            </div>
            
            <div class="venue-section">
              <h3>Venue Info</h3>
              <p>Location: Quinta Nova do Hespanhol</p>
              <p>Address: Rua dos Perestrelos, 2565-136, Portugal</p>
            </div>
          </div>
        `;
      case 'Registry':
        return `
          <div class="registry-content">
            <div class="camera-view">
              <video id="camera-feed" autoplay playsinline></video>
            </div>
            <p class="registry-message">Well, well, well... look who's here! 👀 That gorgeous face is all we need at our wedding. Your presence is worth more than any toaster or fancy plates! ✨ (But if you insist on bringing something, your dance moves will do just fine! 💃🕺)</p>
          </div>
        `;
      case 'Hotel':
        return `
          <div class="hotel-list">
            <div class="hotel-item">
              <h3>Stay Hotel Torres Vedra</h3>
              <p>Price: 80 EUR</p>
              <p>Distance: 20 min from venue</p>
            </div>
            
            <div class="hotel-item">
              <h3>Quinta dos Machados Countryside Hotel & Spa</h3>
              <p>Price: 170 EUR</p>
              <p>Distance: 30 min from venue</p>
            </div>
            
            <div class="hotel-item">
              <h3>Vila Louro</h3>
              <p>Price: 130 EUR</p>
              <p>Distance: 35 min from venue</p>
            </div>
            
            <div class="hotel-item">
              <h3>Reserva FLH Hotels Ericeira</h3>
              <p>Price: 177 EUR</p>
              <p>Distance: 45 min from venue</p>
            </div>
            
            <div class="hotel-item">
              <h3>Empire Hotel Lisbon</h3>
              <p>Price: 90 EUR</p>
              <p>Distance: 60 min from venue</p>
            </div>
          </div>
        `;
      default:
        return "You've stumbled upon an unexplored area of the invitation. What secrets might it hold?";
    }
  };

  const initializeCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: 'user' } });
      const videoElement = document.getElementById('camera-feed') as HTMLVideoElement;
      if (videoElement) {
        videoElement.srcObject = stream;
      }
    } catch (err) {
      console.error('Error accessing camera:', err);
    }
  };

  const toggleSections = () => {
    // No need to toggle sections since they are always visible
  };

  const handleTouchStart = (event: TouchEvent) => {
    if (cursorRef.current) {
      setIsDragging(true);
      const touch = event.touches[0];
      touchPosRef.current = {
        x: touch.clientX,
        y: touch.clientY
      };
      cursorRef.current.style.left = `${touch.clientX}px`;
      cursorRef.current.style.top = `${touch.clientY}px`;
    }
  };

  const checkTouchOverSection = (x: number, y: number) => {
    const sections = document.getElementsByClassName('hover-section');
    for (let i = 0; i < sections.length; i++) {
      const section = sections[i];
      const rect = section.getBoundingClientRect();
      if (x >= rect.left && x <= rect.right && y >= rect.top && y <= rect.bottom) {
        if (cursorRef.current) {
          cursorRef.current.classList.add('section-hover');
        }
        if (section.classList.contains('venue-section')) {
          setHoverSection('Venue');
        } else if (section.classList.contains('rsvp-section')) {
          setHoverSection('RSVP');
        } else if (section.classList.contains('registry-section')) {
          setHoverSection('Registry');
        } else if (section.classList.contains('hotel-section')) {
          setHoverSection('Hotel');
        }
        return true;
      }
    }
    if (cursorRef.current) {
      cursorRef.current.classList.remove('section-hover');
    }
    setHoverSection(null);
    return false;
  };

  const handleTouchMove = (event: TouchEvent) => {
    if (isDragging && cursorRef.current) {
      const touch = event.touches[0];
      touchPosRef.current = {
        x: touch.clientX,
        y: touch.clientY
      };
      cursorRef.current.style.left = `${touch.clientX}px`;
      cursorRef.current.style.top = `${touch.clientY}px`;
      
      checkTouchOverSection(touch.clientX, touch.clientY);
    }
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
    if (cursorRef.current) {
      cursorRef.current.classList.remove('section-hover');
    }
    setHoverSection(null);
  };

  const toggleAudio = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const startExperience = () => {
    if (audioRef.current) {
      audioRef.current.play();
      setIsPlaying(true);
    }
    setShowWelcome(false);
  };

  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      if (cursorRef.current && !isTouchDevice()) {
        cursorRef.current.style.left = `${event.clientX}px`;
        cursorRef.current.style.top = `${event.clientY}px`;
      }
    };

    window.addEventListener('mousemove', handleMouseMove);
    
    if (isTouchDevice()) {
      window.addEventListener('touchstart', handleTouchStart);
      window.addEventListener('touchmove', handleTouchMove);
      window.addEventListener('touchend', handleTouchEnd);
    }

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      if (isTouchDevice()) {
        window.removeEventListener('touchstart', handleTouchStart);
        window.removeEventListener('touchmove', handleTouchMove);
        window.removeEventListener('touchend', handleTouchEnd);
      }
    };
  }, [isDragging]);

  return (
    <div className="wedding-invitation">
      {showWelcome && (
        <div className="welcome-overlay">
          <div className="welcome-content">
            <h5>Welcome [name]!</h5>
            <h6>Invitation better experienced on a phone.</h6>
            <button onClick={startExperience} className="start-button">
              Start ▶️
            </button>
          </div>
        </div>
      )}
      <audio ref={audioRef} loop>
        <source src="/audio/background.mp3" type="audio/mpeg" />
      </audio>
      <button 
        className="audio-control" 
        onClick={toggleAudio}
        aria-label={isPlaying ? 'Pause Music' : 'Play Music'}
      >
        {isPlaying ? '🔇' : '🔊'}
      </button>
      <img 
        src="/images/pred_3.png" 
        alt="8-bit Wedding Invitation" 
        className="invitation-image"
      />
      <div 
        className="hover-section venue-section visible"
        onMouseEnter={() => handleMouseEnter('Venue')}
        onMouseLeave={handleMouseLeave}
        onClick={() => handleSectionClick('Venue')}
      ></div>
      <div 
        className="hover-section rsvp-section visible"
        onMouseEnter={() => handleMouseEnter('RSVP')}
        onMouseLeave={handleMouseLeave}
        onClick={() => handleSectionClick('RSVP')}
      ></div>
      <div 
        className="hover-section registry-section visible"
        onMouseEnter={() => handleMouseEnter('Registry')}
        onMouseLeave={handleMouseLeave}
        onClick={() => handleSectionClick('Registry')}
      ></div>
      <div 
        className="hover-section hotel-section visible"
        onMouseEnter={() => handleMouseEnter('Hotel')}
        onMouseLeave={handleMouseLeave}
        onClick={() => handleSectionClick('Hotel')}
      ></div>
      {hoverSection && (
        <div className="section-hint">{hoverSection}</div>
      )}
      <div 
        ref={cursorRef} 
        className={`custom-cursor ${isDragging ? 'dragging' : ''}`}
        style={{ display: isTouchDevice() ? 'block' : undefined }}
      ></div>
      {overlayContent && (
        <div className="overlay" onClick={() => setOverlayContent(null)}>
          <div className="overlay-content" onClick={e => e.stopPropagation()}>
            <button 
              className="dialog-cancel" 
              onClick={() => setOverlayContent(null)}
              aria-label="Close dialog"
            >
              ✕
            </button>
            <div dangerouslySetInnerHTML={{ __html: overlayContent }} />
          </div>
        </div>
      )}
      {showRSVPForm && (
        <div className="overlay" onClick={() => setShowRSVPForm(false)}>
          <div className="overlay-content rsvp-form" onClick={e => e.stopPropagation()}>
            <button 
              className="dialog-cancel" 
              onClick={() => setShowRSVPForm(false)}
              aria-label="Close RSVP form"
            >
              ✕
            </button>
            <h2>RSVP</h2>
            <p className="rsvp-deadline">Please RSVP by June 15th</p>
            <form onSubmit={handleRSVPSubmit}>
              <div className="form-group">
                <label htmlFor="name">Name:</label>
                <input type="text" id="name" name="name" required />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email:</label>
                <input type="email" id="email" name="email" required />
              </div>
              <div className="form-group">
                <label htmlFor="attending">Will you be attending?</label>
                <select id="attending" name="attending" required>
                  <option value="">Select...</option>
                  <option value="yes">Heck Yeah!</option>
                  <option value="no">No, I have more important things to do.</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="plusOne">Bringing a plus one?</label>
                <select id="plusOne" name="plusOne" required>
                  <option value="">Select...</option>
                  <option value="yes">Yep</option>
                  <option value="no">Nah</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="dietary">Dietary restrictions?</label>
                <textarea id="dietary" name="dietary" rows={2}></textarea>
              </div>
              <div className="form-group">
                <label htmlFor="message">Message for the couple:</label>
                <textarea id="message" name="message" rows={4}></textarea>
              </div>
              <div className="form-actions">
                <button type="submit">Submit RSVP</button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
